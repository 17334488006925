import React from "react";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";

const MagazineIssue = ({ magazine }) => {
	return (
		<Col
			style={{ overflow: "hidden" }}
			className="mb-5 pb-xl-0 h-100"
			md={6}
			lg={4}
			xl={3}
		>
			<a
				href={magazine.magazineEditionFields.fileDownload.mediaItemUrl}
				target="_blank"
				rel="noreferrer"
			>
				<div
					style={{ overflow: "hidden" }}
					className=" w-100 h-100 position-relative magazine-issue-container"
				>
					<GatsbyImage
						style={{ zIndex: -1 }}
						class="mw-100 h-100 magazine-issue-image"
						image={
							magazine.magazineEditionFields.image?.localFile.childImageSharp
								.gatsbyImageData
						}
						alt={magazine.magazineEditionFields.image?.localFile.altText}
					/>
				</div>
			</a>
		</Col>
	);
};

export default MagazineIssue;
