import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import SecondaryHero from "../components/secondary-hero";
import LatestMagazine from "../components/latest-magazine";
import MagazineIssue from "../components/magazine-issue";
const HospitalityMagazinePage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			issues: allWpMagazineEdition(
				sort: { fields: magazineEditionFields___date, order: DESC }
				skip: 1
			) {
				nodes {
					title
					magazineEditionFields {
						date
						fileDownload {
							mediaItemUrl
						}
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							altText
						}
					}
				}
			}
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Hospitality Magazine",
				item: {
					url: `${siteUrl}/hospitality-magazine`,
					id: `${siteUrl}/hospitality-magazine`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Hospitality Magazine | Friends of Eastbourne Hospital"
				description="We produce Hospitality magazine four times a year, filling it with news from the Friends, details of upcoming events and fundraisers, and interesting articles related to the community and the hospital, its staff or volunteers."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/hospitality-magazine`,
					title: "Hospitality Magazine | Friends of Eastbourne Hospital",
					description:
						"We produce Hospitality magazine four times a year, filling it with news from the Friends, details of upcoming events and fundraisers, and interesting articles related to the community and the hospital, its staff or volunteers.",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<SecondaryHero title="Hospitality Magazine" />
				</section>
				<section className="pt-5">
					<LatestMagazine />
				</section>
				<section className="pb-7">
					<Container className="h-100">
						<Row>
							<Col>
								<h2 className="pb-5 fs-1 text-center text-lg-start text-primary">
									Explore our previous editions
								</h2>
							</Col>
						</Row>
						<Row className="justify-content-center ">
							{data.issues.nodes.map((magazine) => (
								<MagazineIssue magazine={magazine} />
							))}
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default HospitalityMagazinePage;
